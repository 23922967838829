import React, { useState, useEffect } from "react"

import styled from "styled-components"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

// import material ui
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

// import app components
import Edges from "components/edges"
import { useStore } from "store"

const ResetPassword = props => {
  const dispatch = useStore()[1]
  const { location } = props

  const key = findGetParameter("key")
  const login = findGetParameter("login")

  const [isResetting, setisResetting] = useState(true)
  const [formError, updateFormErrors] = useState(false)

  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  function findGetParameter(parameterName) {
    let result = null,
      tmp = []
    let items = location.search.substr(1).split("&")
    for (let i = 0; i < items.length; i++) {
      tmp = items[i].split("=")
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    }
    return result
  }

  const sanitizeError = error => {
    if (error.includes("link is invalid")) {
      return "Reset link invalid"
    } else {
      return error
    }
  }

  const [changePassword, response] = useMutation(CHANGE_PASSWORD)

  useEffect(() => {
    const { called, loading, error, data } = response
    called && dispatch({ type: "SET_PROGRESS", payload: loading })
    called && updateFormErrors(false)

    if (called && !error && data && !loading) {
      setisResetting(false)
    }
  }, [response, dispatch])

  const onSubmit = async () => {
    if (!login || !key) {
      updateFormErrors("Reset link invalid")
      return
    }
    if (!password) {
      updateFormErrors("Please enter a new password")
      return
    }

    changePassword({
      variables: { username: login, key, password },
      skip: !password,
    }).catch(res => {
      const errors = res.graphQLErrors.map(({ message }) => message)
      updateFormErrors(sanitizeError(errors))
    })
  }

  return (
    <Container>
      <StyledEdges size="sm">
        <Typography variant="h3" gutterBottom>
          {!!isResetting ? "Reset your password" : "Successful password reset!"}
        </Typography>
        <FormError>{formError}</FormError>
        {!!isResetting ? (
          <div>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              type="password"
              label="New password"
              placeholder="Enter a new password"
              onChange={({ target }) => setPassword(target.value)}
              onKeyPress={e => e.key === `Enter` && onSubmit()}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              type="password"
              label="Confirm New password"
              placeholder="Confirm your new password"
              onChange={({ target }) => setPasswordConfirmation(target.value)}
              onKeyPress={e => e.key === `Enter` && onSubmit()}
            />
            <Button
              fullWidth
              disabled={password === "" || password !== passwordConfirmation}
              onClick={() => onSubmit()}
            >
              Confirm new password
            </Button>
          </div>
        ) : (
          <div>
            <Typography gutterBottom>
              You can now use your new password to login.
            </Typography>
            <Button
              fullWidth
              onClick={() =>
                dispatch({ type: "SET_LOGIN_DIALOG", payload: true })
              }
            >
              Login
            </Button>
          </div>
        )}
      </StyledEdges>
    </Container>
  )
}

export default ResetPassword

const StyledEdges = styled(Edges)`
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Container = styled.div`
  background: white;
`
const StyledTextField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`
const FormError = styled(Typography)`
  && {
    color: black;
  }
`

const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $key: String!
    $username: String!
    $password: String!
  ) {
    resetUserPassword(
      input: {
        key: $key
        login: $username
        password: $password
        clientMutationId: "uniqueId"
      }
    ) {
      clientMutationId
    }
  }
`
